body { font-family: Arial, Helvetica, sans-serif; font-size: 18px;}
a { text-decoration: none;}
.container > header { background: darkcyan; height: 120px;}
.container > nav { 
  background: orange; height: 60px; display: flex;
  justify-content: center; align-items: center; }
.container > main { min-height: 400px;}
.container > main > article{ background: antiquewhite;}
.container > main > aside{ background: lightblue}
.container > footer { background: darkcyan; height: 90px;}

.container > nav ul { list-style: none; margin: 0; padding: 0;}
.container > nav > ul > li { display: inline-block; min-width: 50px; margin: 5px ; text-align: center;}
.container > nav > ul > li > a {  color: white }
.container > nav > ul > li > a:hover {  color:  darkblue }

.home {display: grid; grid-template-columns: auto auto auto; }
.home > .sp {border: 1px solid darkblue; padding: 5px; text-align: center; }
.home > .sp > h4 { height: 40px; font-size: 18px;}
.home > .sp > img { max-width: 100%; height: 150px; }

#spxn > div.sp { padding: 5px 10px; border-bottom: 1px solid darkcyan; font-size: 16px; }

#chitiet > #row1  { display: grid; grid-template-columns: 50% auto; gap: 10px;}
#chitiet > #row1 > #trai > img { max-width: 100%;}
#chitiet > #row1 >#phai p { margin:10px}
#chitiet > #row1 >#phai span { display: inline-block; width: 100px;}

#listsp h1 { font-size: 20px; background-color: darkcyan; color: white; padding: 10px}
#listsp #data {display: grid; grid-template-columns: auto auto auto; }
#listsp #data> .sp {border: 1px solid darkblue; padding: 5px; text-align: center; }
#listsp #data > .sp > h4 { height: 40px; font-size: 18px;}
#listsp #data > .sp > img { max-width: 100%; height: 150px; }

.thanhphantrang { list-style: none; text-align: center;}
.thanhphantrang li { 
  display: inline-block; margin: 5px; width: 45px; height: 45px; line-height: 45px; 
  background: darkcyan; border-radius: 50%;}
.thanhphantrang li a { color:white !important; font-weight: bold;}
.thanhphantrang li.selected {background: orange }
.thanhphantrang li:hover {background: darkolivegreen; cursor: pointer; }

#giohang > h2 {background:darkcyan; color:white; padding:10px; font-size:1.3em}
#giohang > div { 
  display: grid; grid-template-columns: 45% 50px 160px auto 50px; font-size: 18px; } 
#giohang > div > * { border: 1px solid darkcyan ; padding:5px 5px; }
#giohang > div > input:nth-child(2){ text-align: center;}
#giohang > div > span:nth-child(3){ text-align: center;}
#giohang > div > span:nth-child(4){ text-align: right;}
#giohang > div > span:nth-child(5){ text-align: right;}

#frmthanhtoan {  border: 1px solid darkcyan; margin: auto;width: 90%; }
#frmthanhtoan > h2 { background:darkcyan; color:white; padding:10px; font-size:1.3em;}
#frmthanhtoan > div { margin-left: 20px; margin-right: 20px; margin-bottom: 15px; }
#frmthanhtoan label { display: block; }
#frmthanhtoan input { outline: none; width: 100%; padding: 5px;}
#frmthanhtoan button { padding:5px 20px; border:none; background:darkcyan; color:white}
